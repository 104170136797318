// eslint-disable-next-line import/no-anonymous-default-export
export default {
  timeToDate(data, isHideHour) {
    var d = new Date(data); //时间戳10位的需要乘100再处理，13位的不用
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var date = d.getDate();
    var hour = d.getHours();
    var minute = d.getMinutes();
    var second = d.getSeconds();
    if (isHideHour === "s") return year + "-" + this.zero(month) + "-" + this.zero(date) + " " + this.zero(hour) + ":" + this.zero(minute);
    if (isHideHour) return year + "-" + month + "-" + date;
    return year + "-" + this.zero(month) + "-" + this.zero(date) + " " + this.zero(hour) + ":" + this.zero(minute) + ":" + this.zero(second);
  },
  zero(num) {
    return num < 10 ? '0' + num : num
  },
  copy(id, attr) {
    let target = null;

    if (attr) {
      target = document.createElement('div');
      target.id = 'tempTarget';
      target.style.opacity = '0';
      if (id) {
        let curNode = document.querySelector('#' + id);
        target.innerText = curNode[attr];
      } else {
        target.innerText = attr;
      }
      document.body.appendChild(target);
    } else {
      target = document.querySelector('#' + id);
    }

    try {
      let range = document.createRange();
      range.selectNode(target);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      console.log('复制成功')
    } catch (e) {
      console.log('复制失败')
    }

    if (attr) {
      // remove temp target
      target.parentElement.removeChild(target);
    }
  },
  setSessionStorage(key, val) {
    sessionStorage.setItem(key, JSON.stringify(val));
  },
  getSessionStorage(key) {
    let params = sessionStorage.getItem(key);
    try{
      return params ? JSON.parse(params) : null;
    }catch(err) {
      return null;
    }
  },
  removeSessionStorage(key) {
    sessionStorage.removeItem(key);
  },
  setLocalStorage(key, val) {
    localStorage.setItem(key, JSON.stringify(val));
  },
  getLocalStorage(key) {
    let params = localStorage.getItem(key);
    try{
      return params ? JSON.parse(params) : null;
    }catch(err) {
      return null;
    }
  },
  removeLocalStorage(key) {
    localStorage.removeItem(key);
  },
  getQueryString(name) {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    const r = window.location.search.substr(1).match(reg) //search,查询？后面的参数，并匹配正则
    if (r != null) return unescape(r[2])
    return null
  }
}
