import React, { Component, Fragment } from 'react'
import Head from '@components/head'
import { Result, Button } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

import store from '@util/store'
import request from '@util/request'
import cgi from '@config/cgi'

import S from './index.module.scss'

let timer

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uid: null,
      imgUrl: '',
      isLoading: true,
      isError: false,
      isTimeout: false,
      time: 0
    }
  }

  componentDidMount() {
    this.getQrcode()
  }

  componentWillUnmount() {
    timer && clearTimeout(timer)
  }

  getQrcode() {
    request(cgi.qrcode)
      .then(res => this.setState({ imgUrl: res.url, uid: res.uid, isLoading: false, isTimeout: false, time: 0 }, this.getToken))
      .catch(() => this.setState({ isError: true, isTimeout: false }))
  }

  getToken() {
    const { uid, time } = this.state
    if(time >= 60) return this.setState({ isTimeout: true })
    request(cgi.getToken, {
      method: 'PUT',
      data: {
        uid
      }
    }).then(res => {
      this.setState({ time: time + 1 })
      if (res) {
        store.token.set(res.Token)
        store.uinfo.set(res)
        // this.props.history.push('/home');
        window.location.href = window.location.origin + '/note'
      } else {
        timer = setTimeout(() => {
          this.getToken()
        }, 1000);
      }
    })
      .catch(err => console.error(err))
  }

  render() {
    const { imgUrl, isLoading, isError, isTimeout } = this.state

    return (
      <Fragment>
        <Head />
        {!isLoading && !isTimeout && <main className={S.main}>
          <img className={S.img} src={imgUrl} alt='qrcode' />
          <p className={S.text}>打开一念APP &gt; 我的 &gt; 扫一扫 ，扫码登陆</p>
        </main>}
        {isError &&
          <Result
            className={S.result}
            status='error'
            title='请求失败'
          />
        }
        {isTimeout && <Result
          icon={<ClockCircleOutlined />}
          className={S.result}
          title="二维码已过期"
          extra={<Button type="primary" onClick={this.getQrcode.bind(this)}>刷新</Button>}
        />}
      </Fragment>
    )
  }
}
