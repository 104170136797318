import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import folderImg from '@images/folder.png'

import S from './index.module.scss'

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  handleClick(path) {
    this.props.history.push(path);
  }

  changeFolders(index, id, name) {
    this.props.changeFolders(index, id, name)
  }

  render() {
    const { folders, actIndex } = this.props
    return (
      <div className={S.sidebar}>
        <dl className={S.dl}>
          <dt className={S.dt}>便签盒（{folders.length}）</dt>
          <div className={S.dt_box}>
            {
              folders.map((item, index) => <dd key={item.folder_id} className={[S.dd, S.text_ellipsis, index === actIndex ? S.select : ''].join(' ')} onClick={this.changeFolders.bind(this, index, item.folder_id, item.name)}><img className={S.folderImg} src={folderImg} alt='folder' />{item.name}</dd>)
            }
          </div>
        </dl>
      </div>
    )
  }
}

export default withRouter(Sidebar)
