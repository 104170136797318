import React, { Component } from "react"
import { withRouter } from "react-router-dom"

import lang from "@util/language"

import bannerImg from '@img/section4/banner.png'
import keywordImg from '@img/section4/keyword.png'
import linkImg from '@img/section4/link.png'

import S from "./index.module.scss"

class Section1 extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const txt = lang.section_4
    const { keyword, link } = txt

    return (
      <div className={S.section}>
        <div className='wrap'>
          <div className={S.main}>
            <h2 className={S.title} dangerouslySetInnerHTML={{ __html: txt.title }}></h2>
            <p className={S.desc} dangerouslySetInnerHTML={{ __html: txt.desc }}></p>

            <div className={S.sepecial}>
              <h3 className={S.sepecial__title}>
                <img className={S.sepecial__img} src={keywordImg} alt='' />
                {keyword.title}
              </h3>
              <p className={S.sepecial__desc} dangerouslySetInnerHTML={{ __html: keyword.desc }}></p>
            </div>

            <div className={S.sepecial}>
              <h3 className={S.sepecial__title}>
                <img className={S.sepecial__img} src={linkImg} alt='' />
                {link.title}
              </h3>
              <p className={S.sepecial__desc} dangerouslySetInnerHTML={{ __html: link.desc }}></p>
            </div>
          </div>
          <img className={S.img} src={bannerImg} alt='' />
        </div>
      </div>
    )
  }
}

export default withRouter(Section1)
