import React, { Component } from "react"
import { withRouter } from "react-router-dom"

import lang from "@util/language"


import S from "./index.module.scss"

class Section1 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: 0
    }
  }

  handleChange(active) {
    this.setState({ active })
  }

  render() {
    const { active } = this.state
    const txt = lang.section_2
    const { specials } = txt

    return (
      <div className={S.section}>
        <div className='wrap'>
          <div className={S.main}>
            <h2 className={S.title} dangerouslySetInnerHTML={{ __html: txt.title }}></h2>
            <p className={S.desc} dangerouslySetInnerHTML={{ __html: txt.desc }}></p>

            <div className={S.specials}>
              {
                specials.map((v, i) => (
                  <div
                    key={'specials' + i}
                    className={[
                      S.specials_item,
                      active === i ? S.specials_item__active : ''
                    ].join('  ')}
                    onMouseEnter={this.handleChange.bind(this, i)}
                  >
                    <img
                      className={S.specials_item__img}
                      src={active === i ? v.iconAct : v.icon}
                      alt=''
                    />
                    <div className={S.specials_item__right}>
                      <h3 className={S.specials_item__title}>
                        {v.title}
                        {
                          !!v.tag && <span className={[
                            S.specials_item__tag,
                            i === 1 ? S.specials_item__tag2 : ''
                          ].join(' ')}>{v.tag}</span>
                        }
                      </h3>
                      <span className={S.specials_item__txt}>{v.desc}</span>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <img className={S.img} src={specials[active].banner} alt='' />
        </div>
        <div style={{ display: 'none' }}>
          {
            specials.map((v, i) => <img key={'oo' + i} src={v.banner} alt='' />)
          }
        </div>
      </div>
    )
  }
}

export default withRouter(Section1)
