import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Popover } from "antd"

import lang from "@util/language"

import logo from '@images/logo.png'
import down_code from '@images/down_code.png'

import S from "./index.module.scss"

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const txt = lang.footer;

    return (
      <div className={S.footer}>
        <div className='wrap'>
          <img className={S.logo} src={logo} alt='' />
          <h2 className={S.title}>{txt.title}</h2>
          <p className={S.desc}>{txt.desc}</p>

          <Popover
            className={S.nav_popover}
            content={
              <img className={S.btn_img} src={down_code} alt='下载二维码' />
            }
          >
            <button className={S.btn}>{txt.down}</button>
          </Popover>

          <aside className={S.info}>
            <p className={S.copyRight}>{txt.copyRight}</p>
            <a className={S.bean} href='https://beian.miit.gov.cn/'>{txt.bean}</a>
          </aside>
        </div>
      </div>
    )
  }
}

export default withRouter(Footer)
