import utils from "./index"

import icon0 from '@img/section2/0.png'
import icon0Act from '@img/section2/0_active.png'
import bannerImg0 from '@img/section2/0_banner.png'
import icon1 from '@img/section2/1.png'
import icon1Act from '@img/section2/1_active.png'
import bannerImg1 from '@img/section2/1_banner.png'
import icon2 from '@img/section2/2.png'
import icon2Act from '@img/section2/2_active.png'
import bannerImg2 from '@img/section2/2_banner.png'
import icon3 from '@img/section2/3.png'
import icon3Act from '@img/section2/3_active.png'
import bannerImg3 from '@img/section2/3_banner.png'
import icon4 from '@img/section2/4.png'
import icon4Act from '@img/section2/4_active.png'
import bannerImg4 from '@img/section2/4_banner.png'

const language = utils.getQueryString("lang") || "cn"

const cn = {
  header: {
    name: "一念",
    down: "扫码下载App",
    login: "网页端登录",
    loginOut: "退出登录",
  },
  section_1: {
    title: "我们收集<br />是为了更好的使用",
    desc: "使用 一念 构建私人的灵感云仓库。<br />轻松收集图文、快速取用、梳理灵感、激发巧思。",
    down: "下载一念App",
    pai: "少数派 推荐应用",
  },
  section_2: {
    title: "快速收集灵感",
    desc: "你可能会在各种时刻发现值得收藏的文字和图片，<br />一念强大的功能帮你轻松收藏图文，不错过任何灵感的瞬间。",
    specials: [
      {
        title: "共享菜单一键保存",
        desc: "选中文本后点击共享，可快速保存到一念",
        tag: "iOS",
        banner: bannerImg0,
        icon: icon0,
        iconAct: icon0Act,
      },
      {
        title: "复制就是保存",
        desc: "复制文本后点击悬浮窗，可快速保存到一念",
        tag: "安卓",
        banner: bannerImg1,
        icon: icon1,
        iconAct: icon1Act,
      },
      {
        title: "当日图片快速处理",
        desc: "每日新增图片展示，选取灵感图保存到一念",
        tag: null,
        banner: bannerImg2,
        icon: icon2,
        iconAct: icon2Act,
      },
      {
        title: "服务号快捷输入",
        desc: "通过 一念App 服务号快速将图文保存到一念",
        tag: null,
        banner: bannerImg3,
        icon: icon3,
        iconAct: icon3Act,
      },
      {
        title: "网页端快速记录",
        desc: "电脑旁产生灵感，打开网页快速记录到一念",
        tag: null,
        banner: bannerImg4,
        icon: icon4,
        iconAct: icon4Act,
      },
    ],
  },
  section_3: {
    title: "轻松取用灵感",
    desc: "你可能想在其他地方使用收藏好的图片和文字，<br />一念可随时取出仓库里想要的任何内容，面对空白不再手足无措。",
  },
  section_4: {
    title: "灵感整理",
    desc: "你可能积累了大量碎片灵感后发现分类是一个难题，<br />一念使用标签和关联轻松整理，不再纠结灵感该归为哪类。",
    keyword: {
      title: "关键词标签",
      desc: "为任何灵感添加关键词标签，以便更快地找到它们。",
    },
    link: {
      title: "关联",
      desc: "算法会推荐与当前便签相似的已有便签，<mark>帮你建立便签<br />间的潜在联系，并在联系中自由穿梭。</mark>",
    },
  },
  section_5: {
    title: "释放关联无限潜力",
    desc: "新想法的诞生往往来源于对模糊联系的探索，<br />一念让探索的轨迹可视化，帮助你发现新的巧思。",
    keyword: {
      title: "横向瀑布工作台",
      desc: "工作台可随着关联无限展开，足迹清晰可见。",
    },
    link: {
      title: "算法推荐相似便签",
      desc: "每次探索都是独特的轨迹，逻辑与洞见在探索之中涌现。",
    },
  },
  footer: {
    title: "即刻开始积累灵感",
    desc: "通过收藏和整理来创建属于你自己的一念",
    down: "下载一念App",
    copyRight: "©yinian.pro® all rights reserved",
    bean: "粤ICP备20065593号",
  },
};


const en = {
  header: {
    name: "Thoughts",
    down: "Scan To Download",
    login: "Webside login",
    loginOut: "Webside loginout",
  },
  section_1: {
    title: "COLLECTING<br />FOR BETTER USE",
    desc: "Build your private hub of ideas<br />Easily collect images and comb ideas",
    down: "App Download",
    pai: "SSPAI Recommendation",
  },
  section_2: {
    title: "Easily Collect Ideas",
    desc: "You might find words and images worth collecting <br />without missing any inspired moments.",
    specials: [
      {
        title: "Share extension",
        desc: "Click on the share key to save text quickly",
        tag: "iOS",
        banner: bannerImg0,
        icon: icon0,
        iconAct: icon0Act,
      },
      {
        title: "Copy is saving",
        desc: "Click on the float window to save text quickly",
        tag: "Android",
        banner: bannerImg1,
        icon: icon1,
        iconAct: icon1Act,
      },
      {
        title: "Latest images processed quickly",
        desc: "Show images and choose your ideas to save",
        tag: null,
        banner: bannerImg2,
        icon: icon2,
        iconAct: icon2Act,
      },
      {
        title: "Quickly input on WeChat",
        desc: "Quickly save pictures and text by WeChat",
        tag: null,
        banner: bannerImg3,
        icon: icon3,
        iconAct: icon3Act,
      },
      {
        title: "Quickly record on website",
        desc: "Get new ideas and record quickly at work",
        tag: null,
        banner: bannerImg4,
        icon: icon4,
        iconAct: icon4Act,
      },
    ],
  },
  section_3: {
    title: "Get Ideas Easily",
    desc: "You may want to use the pictures and text previously collected at other times，<br />take out whatever you want and stop being overwhelmed by the blankness of your head.",
  },
  section_4: {
    title: "Organize Ideas",
    desc: "By easily organizing with tags and links, <br />no longer tangle with classification.",
    keyword: {
      title: "Keyword Tags",
      desc: "Add keyword tags for any ideas and find them easily",
    },
    link: {
      title: "Link",
      desc: "The algorithm recommends other similar memo.<br />Build inner connections and travel freely among them.",
    },
  },
  section_5: {
    title: "Link Possibilities",
    desc: "New ideas comes from exploration of fuzzy contacts<br />Make the path of exploration visible,Help you discover new ideas.",
    keyword: {
      title: "Horizontal Waterfall Workbench",
      desc: " Unlimited and visualize the path of exploration.",
    },
    link: {
      title: "Algorithm Recommended Similar Memo",
      desc: "Logic and insight in the path of exploration are unique",
    },
  },
  footer: {
    title: "Start Now And Add Your Thoughts",
    desc: "Create your own thoughts by collecting and organizing",
    down: "App Download",
    copyRight: "©yinian.pro® all rights reserved",
    bean: "粤ICP备20065593号",
  },
};

export default language === "cn" ? cn : en
