import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Popover } from 'antd'

import utils from '@util'
import store from '@util/store'
import lang from '@util/language'


import logo from '@images/logo.png'
import down_code from '@images/down_code.png'
import S from './index.module.scss'

const language = utils.getQueryString('lang') || 'cn'

class Head extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.loginOut = this.loginOut.bind(this)
  }

  handleClick(path) {
    if (path === '/home') {
      return window.location.href = window.location.origin + '/note'
    }
    this.props.history.push(path);
  }

  changeLanguage() {
    window.location.href = window.location.origin + window.location.pathname + `?lang=${language === 'en' ? 'cn' : 'en'}`
  }

  loginOut() {
    store.token.remove()
    store.uinfo.remove()
    this.props.history.push('/');
  }

  render() {
    const { name } = this.props
    const isToken = store.token.get()
    const txt = lang.header

    return (
      <header className={[S.header, name === 'home' ? S.header_home : ''].join(' ')}>
        <div className={['wrap', S.wrap].join(' ')}>
          <h1 className={S.logo} onClick={this.handleClick.bind(this, '/')}>
            <img className={S.logo_img} src={logo} alt='logo' />
            {txt.name}
          </h1>
          <nav className={S.nav}>
            <div onClick={this.changeLanguage.bind(this)}>中文/En</div>
            <Popover className={S.nav_popover} placement='bottom' content={
              <img className={S.nav_img} src={down_code} alt='下载二维码' />
            }>{txt.down}</Popover>
            {isToken ? <Popover content={
              <a href='javscript:;' onClick={this.loginOut}>{txt.loginOut}</a>
            }><div className={S.nav_login} onClick={this.handleClick.bind(this, '/home')}>我的便签</div></Popover> : <div className={S.nav_login} onClick={this.handleClick.bind(this, '/login')}>{txt.login}</div>}
          </nav>
        </div>
      </header>
    )
  }
}

export default withRouter(Head)
