import React, { Component, Fragment } from 'react'
import Head from '@components/head'
import Sidebar from '@components/sidebar'
import { message, Result, Button } from 'antd';

import request from '@util/request'
import util from '@util'
import ga from '@util/ga'
import cgi from '@config/cgi'
import store from '@util/store'

import copyImg from '@images/copy.png'
import emptyImg from '@images/empty.png'
import lightImg from '@images/light.png'
import S from './index.module.scss'

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
      folders: [
        { folder_id: null, isSel: true, name: '未分类' }
      ],
      sticky: [],
      index: 0, // 当前显示的便签盒下标
      folder_id: null, // 当前显示的便签盒id
      folder_name: '未分类',
      isError: null,
      isLoading: true
    }

    this.changeFolders = this.changeFolders.bind(this)
    this.saveSticky = this.saveSticky.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const isToken = store.token.get()
    if(!isToken) return this.props.history.push('/login');
    this.getSticky()
  }

  getSticky() {
    request(cgi.link, {
      method: 'POST',
      data: { last_update: 0}
    }).then((res = {}) => {
      const { sticky, folders } = res
      this.setState({ folders: [...this.state.folders, ...folders], sticky, isLoading: false })
    }).catch(err => {
      if(err.code === 401) {
        this.setState({ isError: 'login' })
      } else {
        this.setState({ isError: 'error' })
      }
    })
  }

  saveSticky(ev) {
    ev.preventDefault()
    const { text, folder_id, sticky } = this.state
    let content = text.trim(),
      time = Number(new Date())
    if(!content) return message.warning('请先填写便签内容')
    let data = {
      content,
      create_at: time,
      sticky_id: time
    }
    if(folder_id) data.folder_id = folder_id
    request(cgi.sync, {
      method: 'POST',
      data: { stickies: [data] }
    }).then(() => {
      this.setState({
        text: '',
        sticky: [...sticky, data]
      })
      ga.event('保存便签', '成功')
    }).catch(err => {
      message.error(err.msg || '保存便签失败')
      ga.event('保存便签', '失败')
    })
  }

  changeFolders(index, folder_id, folder_name) { // 切换便签盒
    this.setState({
      index,
      folder_id,
      folder_name
    })
  }

  handleChange(e) {
    this.setState({ text: e.target.value })
  }

  copy(text) {
    util.copy(null, text)
    message.success('复制成功')
    ga.event('复制便签')
  }

  handleClick(type) {
    switch (type) {
      case 'login':
        store.token.remove()
        store.uinfo.remove()
        this.props.history.push('/login');
        break;
      default:
        break;
    }
  }

  render() {
    const { text, folder_id, folder_name, folders, sticky, index, isLoading, isError } = this.state

    const _sticky = sticky.filter(item => {
      if(!folder_id && !item.folder_id) return item
      if(folder_id && folder_id === item.folder_id) return item
      return false
    }).sort((a, b) => b.create_at - a.create_at)

    return (
      <Fragment>
        <Head name='home' />
        {!isLoading && <div className={S.main_box}>
          <Sidebar actIndex={index} folders={folders} changeFolders={this.changeFolders} />
          <div className={S.home}>
            <form>
              <textarea className={S.textarea} placeholder='我想到了...' onChange={this.handleChange} value={text} />
              <div className={S.form_box}>
                <aside className={S.form_aside}>已输入<span className={S.form_aside_num}>{text.length}</span>字</aside>
                <button className={S.btn} onClick={this.saveSticky}>
                  <img className={S.save_img} src={lightImg} alt='light' />
                  保存便签
                </button>
              </div>
            </form>
            <main>
              <h2 className={S.h2}>{folder_name}</h2>
              {
                !_sticky.length && <img className={S.empty_img} src={emptyImg} alt='empty' />
              }
              <ul className={S.main_content}>
                {
                  _sticky.map((item, index) => (
                    <li className={S.li} key={index}>
                      <time className={S.time}>{util.timeToDate(item.create_at, 's')}</time>
                      <p className={S.text}>{item.content}</p>
                      <aside className={S.aside} onClick={this.copy.bind(this, item.content)}>
                        <div className={S.aside_div}><img className={S.aside_img} src={copyImg} alt='copy' />复制</div>
                      </aside>
                    </li>
                  ))
                }
              </ul>
            </main>
          </div>
        </div>}

        {isError &&
          <Result
            className={S.result}
            status={isError !== 'login' ? 'error' : 'info'}
            title={isError === 'login' ? '登录失效，请重新登录' : '服务错误'}
            extra={
              <Button type="primary" key="console" onClick={this.handleClick.bind(this, 'login')}>重新登录</Button>
            }
          />
        }
      </Fragment>
    )
  }
}
