import React, { Component, Fragment } from "react";

import Header from "@components/head";
import Section1 from "./comps/section-1";
import Section2 from "./comps/section-2";
import Section3 from "./comps/section-3";
import Section4 from "./comps/section-4";
import Section5 from "./comps/section-5";
import Footer from "./comps/footer";

// import S from "./index.module.scss";

export default class Index extends Component {
  constructor() {
    super();
    this.state = {
      isShowFooter: process.env.REACT_APP_ENV === "production",
    };
  }

  render() {
    // const { isShowFooter } = this.state;
    return (
      <Fragment>
        <Header />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Footer />
        {/* {isShowFooter && <Footer />} */}
      </Fragment>
    );
  }
}
