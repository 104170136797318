import util from './index';

const prefix = 'copyideas',
  uinfoKey = `${prefix}_uinfo`,
  tokenKey = `${prefix}_token`;

// function _sessionStorage(key) {
//   return {
//     set(data) { util.setSessionStorage(key, data); },
//     get() { return util.getSessionStorage(key); },
//     remove() {
//       util.removeSessionStorage(key);
//     }
//   }
// }

function _localStorage(key) {
  return {
    set(data) { util.setLocalStorage(key, data); },
    get() { return util.getLocalStorage(key); },
    remove() {
      util.removeLocalStorage(key);
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  token:  _localStorage(tokenKey),
  uinfo:  _localStorage(uinfoKey)
}
