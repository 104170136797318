import { HashRouter as Router, Route } from 'react-router-dom'
import Index from '@pages/index'
import Home from '@pages/home/index'
import Login from '@pages/login/index'

export default (
  <Router>
    <Route path='/'  component={Index} exact />
    <Route path='/home'  component={Home} />
    <Route path='/login'  component={Login} />
  </Router>
)