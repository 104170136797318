import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Popover } from "antd"

import lang from "@util/language"

import bannerImg from '@img/section1/banner.png'
import paiImg from '@img/section1/pai.png'
import down_code from '@images/down_code.png'

import S from "./index.module.scss"

class Section1 extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const txt = lang.section_1;

    return (
      <div className={S.section}>
        <div className='wrap'>
          <div className={S.main}>
            <h2 className={S.title} dangerouslySetInnerHTML={{ __html: txt.title }}></h2>
            <p className={S.desc} dangerouslySetInnerHTML={{ __html: txt.desc }}></p>

            <Popover
              className={S.nav_popover}
              content={
                <img className={S.btn_img} src={down_code} alt='下载二维码' />
              }
            >
              <button className={S.btn}>{txt.down}</button>
            </Popover>
            <aside className={S.pai}>
              <img className={S.pai_img} src={paiImg} alt='' />
              <span className={S.pai_txt}>{txt.pai}</span>
            </aside>
          </div>
          <img className={S.banner} src={bannerImg} alt='' />
        </div>
      </div>
    )
  }
}

export default withRouter(Section1)
