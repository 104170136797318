import React, { Component } from "react"
import { withRouter } from "react-router-dom"

import lang from "@util/language"

import bannerImg from '@img/section3/banner.png'
import lightImg from '@img/section3/light.png'

import S from "./index.module.scss"

class Section1 extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const txt = lang.section_3

    return (
      <div className={S.section}>
        <div className='wrap'>
          <div className={S.main}>
            <h2 className={S.title} dangerouslySetInnerHTML={{ __html: txt.title }}></h2>
            <p className={S.desc} dangerouslySetInnerHTML={{ __html: txt.desc }}></p>
          </div>
          <img className={S.img} src={bannerImg} alt='' />
          <img className={S.light} src={lightImg} alt='' />
        </div>
      </div>
    )
  }
}

export default withRouter(Section1)
