const ga = {
  event(name, value = '') {
    if(!~window.location.hostname.indexOf('yinian.pro')) return console.warn('非正式')
    try {
      window.gtag('event', name, { value })
    } catch (error) {
      console.warn('ga事件触发失败，参数：', arguments)
    }
  }
}

export default ga
